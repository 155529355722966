/* eslint-disable */

import Vue from 'vue'
import Router from 'vue-router'
import store from './store.js'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/public/Index'),
      children: [
        { // Login form
          name: 'Login',
          path: '/login',
          component: () => import('@/views/public/Login'),
          meta: {requiresAuth: false}} ,
        { // Public Home
          name: 'PublicHome',
          path: '/publichome',
          component: () => import('@/views/public/PublicHome'), 
          meta: {requiresAuth: false},
        },
    ]},
//
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
          meta: {requiresAuth: true},
      children: [
        { // Site Home
          name: 'SiteHome',
          path: '/sitehome',
          component: () => import('@/views/dashboard/SiteHome'), 
          meta: {requiresAuth: true},
        },
        { // Site Dashboard
          name: 'Main Dashboard',
          path: '/dashboards/maindashboard',
          component: () => import('@/views/dashboard/MainDashboard'), 
          meta: {requiresAuth: true},
        },
        { // Combo Chart 1 *******************************
          name: 'Combo Chart 1',
          props: true,
          path: '/basereports/combochart1/:id',
          component: () => import('@/views/dashboard/ComboChart1'),
          meta: {requiresAuth: true},
        },
        { // Well Chart 1 *******************************
          name: 'Well Chart 1',
          props: true,
          path: '/basereports/wellchart1/:id',
          component: () => import('@/views/dashboard/WellChart1'),
          meta: {requiresAuth: true},
        },
        { // Combo Table *******************************
          name: 'Combo Table',
          props: true, 
          path: '/basereports/combotable/:id',
          component: () => import('@/views/dashboard/ComboTable'),
          meta: {requiresAuth: true},
        },
        { // MeterChecks Table *******************************
          name: 'MeterChecks Table',
          props: true, 
          path: '/basereports/metercheckstable/:id',
          component: () => import('@/views/dashboard/MeterChecks'),
          meta: {requiresAuth: true},
        },
        { // Datalog Import *******************************
          name: 'Datalog Import',
          path: '/utilities/datalogimport',
          component: () => import('@/views/dashboard/DatalogImport'),
          meta: {requiresAuth: true},
        },
        { // Weather Dashboard *******************************
          name: 'Weather Dashboard',
          path: '/basereports/weatherdashboard',
          component: () => import('@/views/dashboard/WeatherDashboard'),
          meta: {requiresAuth: true},
        },
        {
          name: 'About',
          path: '/docs/about',
          component: () => import('@/views/dashboard/About'),
          meta: {requiresAuth: true},
        },
        {
          name: 'Intraprem User Help',
          path: '/docs/userhelp',
          component: () => import('@/views/dashboard/UserHelp'),
          meta: {requiresAuth: true},
        },
        {
          name: 'Intraprem Technical Notes',
          path: '/docs/technotes',
          component: () => import('@/views/dashboard/TechNotes'),
          meta: {requiresAuth: true},
        },
        {
          name: 'User Profile',
          path: '/userprofile',
          meta: {requiresAuth: true},
          component: () => import('@/views/dashboard/UserProfile'),
        },
      ],
    },    
    //****** Page not found
    { // Page not found
      name: 'Page Not Found',
    path: '*',
    component: () => import('@/pages/404.vue')
  },      
],

});
// Nav Guard Hooks
router.beforeEach((to,from,next) => {
  if (to.meta.requiresAuth) {
    // Are we logged in?
    if (store.state.loggedin.state) {
      // we are logged in so proceed
      next();
    } else {
    // need to login
      next("/login")
    }
  } else { // No login required so proceed
    next();
  }
});

export default router;
