// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import store from './store'
// import Editor from '@tinymce/tinymce-vue'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueFriendlyIframe from 'vue-friendly-iframe'
// import VueSweetalert2 from 'vue-sweetalert2'
import VueEllipseProgress from 'vue-ellipse-progress'

export const ebus = new Vue()
Vue.use(VueEllipseProgress, 'vueprogress')
Vue.use(VueFriendlyIframe)
Vue.use(Vuex)
// Vue.use(Editor)
// Vue.use(VueSweetalert2)
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
