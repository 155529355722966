/* eslint-disable */

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    packageVersion: '0.0.5',
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '/src/assets/img/sidebar-4.jpg',
    drawer: true,
    // ***** Central indicators of login state
    loggedin: { 
      state: false,
      userUid: null,
      firstName: null,
      lastName: null
    },
    current_customer: {
      customerUid: null,
      organizationName: null,
    },
    current_site: {
      uid: null, 
      name: null,
    }, 
    //*****
  },
  mutations: {
    SET_LOGIN (state, payload) {
      state.loggedin = payload
    },
    SET_CURRENT_SITE (state, payload) {
      if (payload == null) {
        state.current_site = state.default_site
      } else {
      state.current_site = payload
      }
    },
    // SET_BAR_IMAGE (state, payload) {
    //   state.barImage = payload
    // },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion
    },
    getloggedin: state => {
      return state.loggedin.state
    },

  },
})
