<!-- 
**** This is the core component that Vue starts with to build up the Vue SPA
**** <router-view /> inserts the content based on the route.  
**** See Index.vue for how the SPA page is brought together.
-->
<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
  }
</script>
